var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "b-avatar",
                  { attrs: { rounded: "", variant: "light-primary" } },
                  [
                    _c("feather-icon", {
                      staticClass: "text-dark font-weight-bolder",
                      attrs: { icon: "CalendarIcon", size: "18" }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: { "padding-top": "8px" }
                  },
                  [
                    _c("h4", { staticClass: " font-weight-700" }, [
                      _vm._v(" Shifts ")
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "b-card-body",
            { staticClass: " pl-1 px-0 mx-0" },
            [
              _c(
                "vue-perfect-scrollbar",
                {
                  staticClass:
                    "todo-task-list-wrapper list-group scroll-area min-vh-50 ",
                  staticStyle: { "max-height": "315px" }
                },
                _vm._l([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], function(index) {
                  return _c(
                    "div",
                    { staticClass: "d-flex flex-row my-1 border-bottom" },
                    [
                      _c("div", { staticClass: "pl-1 " }, [
                        _c(
                          "h5",
                          { staticClass: "font-weight-700" },
                          [
                            _vm._v("Tottenham Hale Medical Practice "),
                            _c(
                              "b-badge",
                              {
                                staticClass: "text-capitalize  mr-2",
                                attrs: { variant: "light-danger" }
                              },
                              [_vm._v(" COVID-19 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "mr-1" },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CalendarIcon" }
                            }),
                            _vm._v(" Apr 15, 2021 - Jun 15, 2021 ")
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "mr-1" },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "DatabaseIcon" }
                            }),
                            _vm._v(" £750,000 ")
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-auto " },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize mt-1 mr-1",
                              attrs: { variant: "light-success" }
                            },
                            [_vm._v(" Active ")]
                          ),
                          _c("ProfileShiftsDropDownMenu")
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }