var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("profile-image-bar"),
      _c("ProfileSecondCardRow", {
        on: {
          toggleEditSideBar: function($event) {
            _vm.isProfileEditLocationSidebarActive = true
          },
          toggleLeavesSideBar: function($event) {
            _vm.isAddLeavesSidebarActive = true
          }
        }
      }),
      _c("ProfileShifts"),
      _c("ProfileEditConnectedLocationsSideBar", {
        attrs: {
          "is-profile-edit-location-sidebar-active":
            _vm.isProfileEditLocationSidebarActive
        },
        on: {
          "update:isProfileEditLocationSidebarActive": function($event) {
            _vm.isProfileEditLocationSidebarActive = $event
          },
          "update:is-profile-edit-location-sidebar-active": function($event) {
            _vm.isProfileEditLocationSidebarActive = $event
          }
        }
      }),
      _c("AddLeavesSideBar", {
        ref: "CreateLeaveSideBarSideBar",
        attrs: { "is-add-leaves-sidebar-active": _vm.isAddLeavesSidebarActive },
        on: {
          "update:isAddLeavesSidebarActive": function($event) {
            _vm.isAddLeavesSidebarActive = $event
          },
          "update:is-add-leaves-sidebar-active": function($event) {
            _vm.isAddLeavesSidebarActive = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }