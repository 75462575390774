var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isAddLeavesSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-add-leaves-sidebar-active", val)
      },
      hidden: _vm.resetForm
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [_vm._v(" Add Leave ")]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c("validation-observer", {
              ref: "refFormObserver",
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "b-form",
                          {
                            staticClass: "p-3",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.onSubmit)
                              },
                              reset: function($event) {
                                $event.preventDefault()
                                return _vm.resetForm($event)
                              }
                            }
                          },
                          [
                            _c("label", { staticClass: "font-weight-700 " }, [
                              _vm._v("Leave Type")
                            ]),
                            _c(
                              "div",
                              { staticClass: "demo-inline-spacing " },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary mt-1",
                                    attrs: {
                                      name: "some-radio9",
                                      value: "time"
                                    },
                                    model: {
                                      value: _vm.form.leave_type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "leave_type", $$v)
                                      },
                                      expression: "form.leave_type"
                                    }
                                  },
                                  [
                                    _vm._v(" Time Off "),
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value:
                                            "The clinician is unavailable for specific hours ",
                                          expression:
                                            "'The clinician is unavailable for specific hours '",
                                          modifiers: { hover: true, top: true }
                                        }
                                      ],
                                      staticClass: "text-primary ml-50",
                                      attrs: { icon: "InfoIcon", size: "12" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary mt-1 ",
                                    attrs: {
                                      name: "some-radio9",
                                      value: "day"
                                    },
                                    model: {
                                      value: _vm.form.leave_type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "leave_type", $$v)
                                      },
                                      expression: "form.leave_type"
                                    }
                                  },
                                  [
                                    _vm._v(" Day Off "),
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value:
                                            "The clinician is unavailable for more than one full day",
                                          expression:
                                            "'The clinician is unavailable for more than one full day'",
                                          modifiers: { hover: true, top: true }
                                        }
                                      ],
                                      staticClass: "text-primary ml-50",
                                      attrs: { icon: "InfoIcon", size: "12" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.form.leave_type === "time"
                              ? _c(
                                  "div",
                                  [
                                    _vm._l(_vm.form_reapeater_row, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "b-row",
                                        {
                                          key: item.id,
                                          staticClass: "mt-2 ",
                                          attrs: { id: item.id }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(
                                                        validationContext
                                                      ) {
                                                        return [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label: "Date",
                                                                "label-for":
                                                                  "date"
                                                              }
                                                            },
                                                            [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  placeholder:
                                                                    "Select"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.start_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.start_date = $$v
                                                                  },
                                                                  expression:
                                                                    "start_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "b-form-invalid-feedback",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        validationContext
                                                                          .errors[0]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Start Date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(
                                                        validationContext
                                                      ) {
                                                        return [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Start Date",
                                                                "label-for":
                                                                  "start_date"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-timepicker",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    id:
                                                                      "start_date",
                                                                    state: _vm.getValidationState(
                                                                      validationContext
                                                                    ),
                                                                    placeholder:
                                                                      "Select",
                                                                    trim: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.start_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.start_date = $$v
                                                                    },
                                                                    expression:
                                                                      "start_date"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "b-form-invalid-feedback",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        validationContext
                                                                          .errors[0]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "End Date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(
                                                        validationContext
                                                      ) {
                                                        return [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "End Date",
                                                                "label-for":
                                                                  "start_date"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-timepicker",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    id:
                                                                      "end_date",
                                                                    state: _vm.getValidationState(
                                                                      validationContext
                                                                    ),
                                                                    placeholder:
                                                                      "Select",
                                                                    trim: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.start_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.start_date = $$v
                                                                    },
                                                                    expression:
                                                                      "start_date"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "b-form-invalid-feedback",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        validationContext
                                                                          .errors[0]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: { variant: "link" },
                                                on: { click: _vm.repeatAgain }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "mr-50",
                                                  attrs: { icon: "PlusIcon" }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "align-middle"
                                                  },
                                                  [_vm._v(" Add Date")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "b-row",
                                      { staticClass: "mt-2 " },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Date",
                                                rules: "required"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(
                                                      validationContext
                                                    ) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label: "Date",
                                                              "label-for":
                                                                "date"
                                                            }
                                                          },
                                                          [
                                                            _c("flat-pickr", {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.start_date,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.start_date = $$v
                                                                },
                                                                expression:
                                                                  "start_date"
                                                              }
                                                            }),
                                                            _c(
                                                              "b-form-invalid-feedback",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      validationContext
                                                                        .errors[0]
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Date",
                                                rules: "required"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(
                                                      validationContext
                                                    ) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label: "Date",
                                                              "label-for":
                                                                "date"
                                                            }
                                                          },
                                                          [
                                                            _c("flat-pickr", {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.start_date,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.start_date = $$v
                                                                },
                                                                expression:
                                                                  "start_date"
                                                              }
                                                            }),
                                                            _c(
                                                              "b-form-invalid-feedback",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      validationContext
                                                                        .errors[0]
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold  mt-1" },
                                  [_vm._v("Reason")]
                                ),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    dir: _vm.$store.state.appConfig.isRTL
                                      ? "rtl"
                                      : "ltr",
                                    options: _vm.reasons,
                                    label: "title",
                                    placeholder: "Select"
                                  },
                                  model: {
                                    value: _vm.reason,
                                    callback: function($$v) {
                                      _vm.reason = $$v
                                    },
                                    expression: "reason"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-3 pl-1" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    }
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "text-primary ml-1",
                                    attrs: {
                                      type: "button",
                                      variant: "outline-white"
                                    },
                                    on: { click: hide }
                                  },
                                  [_vm._v(" Cancel ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }