var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "FileIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(" Documents ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Download all!",
                              expression: "'Download all!'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "DownloadIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass:
                            "todo-task-list-wrapper list-group scroll-area min-vh-50",
                          staticStyle: { "max-height": "315px" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-group list-group-flush" },
                            [
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/excel.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/excel.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/excel.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _c(
                                    "b-media",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1 ",
                                        attrs: {
                                          src: require("@/assets/images/filetypes/pdf.png"),
                                          alt: "browser img",
                                          height: "30"
                                        }
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "align-self-center my-auto font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " Biochemistry Certification "
                                          ),
                                          _c("br"),
                                          _c(
                                            "small",
                                            { staticClass: "text-dark" },
                                            [
                                              _vm._v(
                                                "47 Crown Street, London, W1H 8BR"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "CoffeeIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(" Leaves ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Add",
                              expression: "'Add'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "PlusIcon", size: "18" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("toggleLeavesSideBar")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass:
                            "todo-task-list-wrapper list-group scroll-area min-vh-50 ",
                          staticStyle: { "max-height": "315px" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-group list-group-flush" },
                            _vm._l([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], function(
                              item
                            ) {
                              return _c(
                                "li",
                                {
                                  staticClass: "list-group-item font-black pr-0"
                                },
                                [
                                  _vm._v("Tottenham Hale Medical Practice "),
                                  _c("DropDownMenu"),
                                  _c("br"),
                                  _c("small", { staticClass: "text-dark" }, [
                                    _vm._v("47 Crown Street, London, W1H 8BR")
                                  ])
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-bolder",
                              attrs: { icon: "MapPinIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(" Connected Locations ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Edit",
                              expression: "'Edit'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "EditIcon", size: "18" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("toggleEditSideBar")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass:
                            "todo-task-list-wrapper list-group scroll-area min-vh-50",
                          staticStyle: { "max-height": "315px" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-group list-group-flush" },
                            _vm._l([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], function(
                              index
                            ) {
                              return _c(
                                "li",
                                { staticClass: "list-group-item font-black" },
                                [
                                  _vm._v("Tottenham Hale Medical Practice "),
                                  _c("br"),
                                  _c("small", { staticClass: "text-dark" }, [
                                    _vm._v("47 Crown Street, London, W1H 8BR")
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }