<template>
  <div>
    <profile-image-bar></profile-image-bar>

    <ProfileSecondCardRow
        @toggleEditSideBar="isProfileEditLocationSidebarActive = true"
        @toggleLeavesSideBar="isAddLeavesSidebarActive = true"
    />

    <ProfileShifts/>

    <!--    Sidebar-->
    <ProfileEditConnectedLocationsSideBar
        :is-profile-edit-location-sidebar-active.sync="isProfileEditLocationSidebarActive"/>
    <AddLeavesSideBar
        ref="CreateLeaveSideBarSideBar"
        :is-add-leaves-sidebar-active.sync="isAddLeavesSidebarActive"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import ProfileImageBar from "@/views/profile/includes/ProfileImageBar";
import ProfileShifts from "@/views/profile/includes/ProfileShifts";
import ProfileSecondCardRow from "@/views/profile/includes/ProfileSecondCardRow";

import ProfileEditConnectedLocationsSideBar from "@/views/profile/sidebars/ProfileEditConnectedLocationsSideBar";
import AddLeavesSideBar from "@/views/profile/sidebars/leaves/AddLeavesSideBar";

export default {
  components: {
    AddLeavesSideBar,
    ProfileEditConnectedLocationsSideBar,
    ProfileImageBar,
    ProfileShifts,
    ProfileSecondCardRow,
    VuePerfectScrollbar,
  },
  data() {
    return {
      isAddLeavesSidebarActive: false,
      isProfileEditLocationSidebarActive: false,
    }
  }
}
</script>
