var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isProfileEditLocationSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-profile-edit-location-sidebar-active", val)
      },
      hidden: _vm.resetForm
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" Edit Connected Locations ")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c("validation-observer", {
              ref: "refFormObserver",
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "b-form",
                          {
                            staticClass: "p-2",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.onSubmit)
                              },
                              reset: function($event) {
                                $event.preventDefault()
                                return _vm.resetForm($event)
                              }
                            }
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "font-small-3 font-weight-bold pb-1"
                              },
                              [_vm._v("Manage locations where clinician works")]
                            ),
                            _c(
                              "b-input-group",
                              { staticClass: "input-group-merge" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  { attrs: { "is-text": "" } },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "SearchIcon" }
                                    })
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  attrs: { placeholder: "Search" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "vue-perfect-scrollbar",
                              {
                                staticClass:
                                  "todo-task-list-wrapper list-group scroll-area min-vh-50 mt-2",
                                staticStyle: {
                                  height: "70vh",
                                  border: "1px solid #E5E5E5",
                                  "border-radius": "4px"
                                },
                                attrs: {
                                  settings: _vm.perfectScrollbarSettings
                                }
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "list-group" },
                                  _vm._l(_vm.tasks, function(task) {
                                    return _c(
                                      "li",
                                      {
                                        key: task.id,
                                        staticClass:
                                          "todo-item py-1 list-group-item",
                                        class: { completed: task.isCompleted }
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              checked: task.isCompleted
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(task.title))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    }
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "text-primary ml-1",
                                    attrs: {
                                      type: "button",
                                      variant: "outline-white"
                                    },
                                    on: { click: hide }
                                  },
                                  [_vm._v(" Cancel ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }