var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            {},
            [
              _c("b-col", { attrs: { cols: "12", xl: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-md-flex flex-row mb-2  text-md-left text-center"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "shadow-sm font-weight-bold p-1 align-self-baseline float-left",
                        attrs: { variant: "white" },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon", size: "20" }
                        })
                      ],
                      1
                    ),
                    _c("b-avatar", {
                      staticClass: "mt-2 mr-3 mr-md-0",
                      attrs: {
                        src: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
                        size: "100px"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "m-2 font-weight-bolder profile-text" },
                      [
                        _c("h3", { staticClass: "text-dark" }, [
                          _vm._v("Monika Smith")
                        ]),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "font-small-3 font-weight-light"
                                },
                                [_vm._v("Dietician")]
                              )
                            ]),
                            _c("b-col", { attrs: { cols: "12   mt-1" } }, [
                              _c(
                                "span",
                                { staticClass: "mr-1" },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "PhoneIcon" }
                                  }),
                                  _vm._v(" 078 4914 9377 ")
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "mr-1" },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "MailIcon" }
                                  }),
                                  _vm._v(" hello@tottenham.co.uk ")
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "mr-1" },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "PocketIcon" }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "pl-0 " },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "mt-1 font-weight-light",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { variant: "light-info" }
                                  },
                                  [
                                    _c("span", { staticClass: "text-dark " }, [
                                      _vm._v("Dietology")
                                    ])
                                  ]
                                ),
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr-1 my-1 my-md-0",
                                    attrs: { variant: "light-info" }
                                  },
                                  [
                                    _c("span", { staticClass: "text-dark" }, [
                                      _vm._v(" Food Processing")
                                    ])
                                  ]
                                ),
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { variant: "light-info" }
                                  },
                                  [
                                    _c("span", { staticClass: "text-dark" }, [
                                      _vm._v(" Nutrition")
                                    ])
                                  ]
                                ),
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr-1  mb-1 my-md-0",
                                    attrs: { variant: "light-info" }
                                  },
                                  [
                                    _c("span", { staticClass: "text-dark" }, [
                                      _vm._v(" Biochemistry")
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-col",
                {
                  staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ",
                  attrs: { cols: "12", xl: "6" }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-center justify-content-xl-end",
                          attrs: { cols: "12", md: "7" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center pr-xl-4 pr-2"
                            },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    variant: "light-primary",
                                    rounded: ""
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "ClockIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v(" 50 ")]
                                ),
                                _c("small", [_vm._v("Hours Worked")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center pr-bx" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    variant: "light-primary",
                                    rounded: ""
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "CreditCardIcon",
                                      size: "18"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v(" £2,650.00 ")]
                                ),
                                _c("small", [_vm._v("Earned")])
                              ])
                            ],
                            1
                          )
                        ]
                      ),
                      _c("b-col", {
                        staticClass: " pr-3 pl-3 pl-md-0  my-2 my-md-0",
                        attrs: { cols: "12", md: "5" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }